import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { SynthroneDataService } from '@synthroneApp/services/synthrone-data/synthrone-data.service';
import { AuthenticateService, CommonFunctionsService, CookieService, NotificationsService } from '@angular/synthrone-design/services';



@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppLayoutComponent {

  constructor(@Inject(SynthroneDataService) public SynthroneDS , @Inject(CookieService) public cookieService,  @Inject(CommonFunctionsService) public SynthroneCommon, @Inject(AuthenticateService) public authenticateService, @Inject(NotificationsService) public notificationsService) { }

}
