import {Component, AfterViewInit, ViewChild, Inject} from '@angular/core';
import {NotificationsService} from '@angular/synthrone-design/services';
import {NotificationsComponent} from '@angular/synthrone-design/components';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})

export class NotificationsViewComponent implements AfterViewInit {

    @ViewChild('notificationsContainer') notificationsContainer: NotificationsComponent;

    constructor(@Inject(NotificationsService) public notificationsService) {
    }

    ngAfterViewInit() {
        this.notificationsContainer.getNotifications(20);
    }
}