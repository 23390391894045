import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {AwsAuthenticatedSdkService} from '@synthroneApp/services/aws-authenticated-sdk/aws-authenticated-sdk.service';
import {Subscription, interval} from 'rxjs';
import {MessagesService} from '@angular/synthrone-design/services/messages/messages.service';
import {ErrorsListModalComponent} from '@synthroneApp/components/views/import/errors-list-modal/errors-list-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {CookieService} from '@angular/synthrone-design/services/cookie/cookie.service';
import {GetObjectCommand, S3Client} from '@aws-sdk/client-s3';
import {InvokeCommand, LambdaClient} from '@aws-sdk/client-lambda';
import { Buffer } from 'buffer/';

@Component({
    selector: 'import-status',
    templateUrl: './import-status.component.html',
    styleUrls: ['./import-status.component.scss']
})
export class ImportStatusComponent implements OnInit, OnDestroy {
    @Input() jobDetails;
    @Input() isRetry;
    @Output() isTrustedContentGatheringFinished = new EventEmitter<boolean>();
    @Output() isPlatformDataGatheringFinished = new EventEmitter<boolean>();
    @Output() isCompleted = new EventEmitter<boolean>();
    @Output() isFailed = new EventEmitter<boolean>();
    isLoading = false;
    intervalId: any;
    subscription: Subscription;
    isTrackerDataGathered = false;
    isBrowserDataGathered = false;
    isProductDataGathered = false;
    isProductDataValidation = true;
    isProductDataImport = false;
    isProductPlatformDataValidation = false;
    isProductPlatformDataImport = false;
    isProductDataValidationSucceeded = false;
    isProductDataImportSucceeded = false;
    isProductPlatformDataValidationSucceeded = false;
    isProductPlatformDataImportSucceeded = false;
    isProductDataImportScheduled = true;
    isProductPlatformDataValidationScheduled = true;
    isProductPlatformDataImportScheduled = true;
    jsonData: any;
    trackerGatheredData: number;
    browserGatheredData: number;
    productGatheredData: number;
    platformSentProducts: number;
    productPath: string;
    productPlatformPath: string;
    errorList: any;

    constructor(
        protected awsService: AwsAuthenticatedSdkService,
        private messageService: MessagesService,
        protected dialog: MatDialog,
        private cookieService: CookieService
    ) {
    }

    ngOnInit(): void {
        if (this.jobDetails.stage.toString() !== '3') {
            this.getS3Object().then(response => {
                if (response.errorType) {
                    this.messageService.showSnack(response.errorMessage, 'error')
                } else {
                    response.Body.transformToString().then(result => {
                        this.jsonData = JSON.parse(result);
                        if (this.jobDetails.stage.toString() === '1') {
                            this.trackerGatheredData = this.jsonData.filter(row => row['meta']['tracker']['gathered'] === true).length;
                            this.browserGatheredData = this.jsonData.filter(row => row['meta']['browser']['gathered'] === true || row['meta']['tracker']['found'] === true).length;
                            if (this.trackerGatheredData === this.jsonData.length) {
                                this.isTrackerDataGathered = true;
                            }
                            if (this.browserGatheredData === this.jsonData.length) {
                                this.isBrowserDataGathered = true;
                            }
                            if (!this.isTrackerDataGathered || !this.isBrowserDataGathered) {
                                this.getData();
                            }
                            if (this.isTrackerDataGathered === true && this.isBrowserDataGathered === true) {
                                this.isTrustedContentGatheringFinished.emit(true)
                            }
                        } else if (this.jobDetails.stage.toString() === '2') {
                            this.productGatheredData = this.jsonData.filter(row => row['meta']['platform']['gathered'] === true).length
                            this.platformSentProducts = this.jsonData.filter(row => row['meta']['platform']['sent'] === true).length
                            if (this.productGatheredData === this.platformSentProducts) {
                                this.isPlatformDataGatheringFinished.emit(true)
                                this.isProductDataGathered = true;
                            } else {
                                this.getData();
                            }
                        }
                    });
                }
            })
        } else {
            this.getImportFile().then(response => {
                if (response.errorType) {
                    this.messageService.showSnack(response.errorMessage, 'error')
                } else {
                    this.prepareStatuses(response, true);
                }
            });
        }

        this.isLoading = false;
    }

    getData() {
        const source = interval(10000);
        if (this.jobDetails.stage.toString() !== '3') {
            this.subscription = source.subscribe(val =>
                this.getS3Object().then(response => {
                    if (response.errorType) {
                        this.messageService.showSnack(response.errorMessage, 'error')
                    } else {
                        response.Body.transformToString().then(result => {
                            this.jsonData = JSON.parse(result);
                            if (this.jobDetails.stage.toString() === '1') {
                                this.trackerGatheredData = this.jsonData.filter(row => row['meta']['tracker']['gathered'] === true).length;
                                this.browserGatheredData = this.jsonData.filter(row => row['meta']['browser']['gathered'] === true || row['meta']['tracker']['found'] === true).length;
                                if (this.trackerGatheredData === this.jsonData.length) {
                                    this.isTrackerDataGathered = true;
                                }
                                if (this.browserGatheredData === this.jsonData.length) {
                                    this.isBrowserDataGathered = true;
                                }
                                if (this.isTrackerDataGathered === true && this.isBrowserDataGathered === true) {
                                    this.subscription.unsubscribe();
                                    this.isTrustedContentGatheringFinished.emit(true)
                                }
                            } else if (this.jobDetails.stage.toString() === '2') {
                                this.productGatheredData = this.jsonData.filter(row => row['meta']['platform']['gathered'] === true).length
                                if (this.productGatheredData === this.platformSentProducts) {
                                    this.isProductDataGathered = true;
                                    this.isPlatformDataGatheringFinished.emit(true)
                                    this.subscription.unsubscribe();
                                }
                            }
                        });
                    }
                })
            );
        } else {
            this.subscription = source.subscribe(val =>
                this.getImportFile().then(response => {
                    if (response.errorType) {
                        this.messageService.showSnack(response.errorMessage, 'error')
                    } else {
                        this.prepareStatuses(response)
                    }
                })
            );
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    getS3Object(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const params = {
                    Bucket: this.awsService.getBucket(),
                    Key: this.jobDetails.user_id ? `explorer/jobs/${this.jobDetails.user_id}/${this.jobDetails.job_id}/data.json` : `explorer/jobs/${this.jobDetails.userId}/${this.jobDetails.jobId}/data.json`
                }

                this.awsService.getInstance().subscribe(config => {
                    const s3 = new S3Client(config);

                    s3.send(
                        new GetObjectCommand(params), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        return resolve(data);
                    });
                });
            })
    };

    getImportFile(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const params = {
                    Bucket: this.awsService.getBucket(),
                    Key: this.jobDetails.user_id ? `explorer/jobs/${this.jobDetails.user_id}/${this.jobDetails.job_id}/import.json` : `explorer/jobs/${this.jobDetails.userId}/${this.jobDetails.jobId}/import.json`
                }

                this.awsService.getInstance().subscribe(config => {
                    const s3 = new S3Client(config);

                    s3.send(
                        new GetObjectCommand(params), function (error, data) {
                        if (error) {
                            return reject(error)
                        }
                        return resolve(data);
                    });
                });
            });
    };

    downloadData(type) {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'jobXlsxImportUrl',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }).then(response => {
                if (response['errorType']) {
                    this.messageService.showSnack(response['errorMessage'], 'error')
                } else {
                    if (type === 'product') {
                        window.open(response['product'], '_blank');
                    } else {
                        window.open(response['productPlatform'], '_blank');
                    }

                }
            })
    }

    showErrorLogs() {
        const dialogRef = this.dialog.open(ErrorsListModalComponent, {
            width: '55%',
            height: '70%',
            panelClass: 'dialog-errors-list',
            autoFocus: false,
            data: this.errorList.hasOwnProperty('validation') ? this.errorList.validation : this.errorList
        })
    }

    prepareStatuses(response, isInit = false) {
        response.Body.transformToString().then(result => {
            this.jsonData = JSON.parse(result);
            this.productPath = this.jsonData['paths']['product'];
            this.productPlatformPath = this.jsonData['paths']['productPlatform'];
            if (this.jsonData.stage === 'productValidation') {
                this.isProductDataValidationSucceeded = this.jsonData.status === 'done'
                if (this.jsonData.status === 'aborted') {
                    this.isProductDataValidation = false;
                    // if (this.subscription) {
                    //     this.subscription.unsubscribe();
                    // }
                } else if (this.jsonData.status === 'failed') {
                    this.isProductDataValidation = false;
                    this.errorList = this.jsonData.error;
                    this.isFailed.emit(true);
                    if (this.subscription) {
                        this.subscription.unsubscribe();
                    }
                } else if (this.jsonData.status === 'done') {
                    this.isProductDataValidation = false;
                    this.isProductDataImport = true;
                    this.isProductDataImportScheduled = false;
                }
            } else if (this.jsonData.stage === 'productExecution') {
                this.isProductDataValidationSucceeded = true;
                this.isProductDataValidation = false;
                this.isProductDataImport = true;
                this.isProductDataImportScheduled = false;
                this.isProductDataImportSucceeded = this.jsonData.status === 'done';
                if (this.jsonData.status === 'aborted') {
                    this.isProductDataImport = false;
                    // if (this.subscription) {
                    //     this.subscription.unsubscribe();
                    // }
                } else if (this.jsonData.status === 'failed') {
                    this.isProductDataImport = false;
                    this.errorList = this.jsonData.error;
                    this.isFailed.emit(true);
                    if (this.subscription) {
                        this.subscription.unsubscribe();
                    }
                } else if (this.jsonData.status === 'done') {
                    this.isProductDataImport = false;
                    this.isProductPlatformDataValidation = true;
                    this.isProductPlatformDataValidationScheduled = false;
                }
            } else if (this.jsonData.stage === 'productPlatformValidation') {
                this.isProductDataImport = false;
                this.isProductDataValidationSucceeded = true;
                this.isProductDataImportSucceeded = true;
                this.isProductDataValidation = false;
                this.isProductPlatformDataValidation = true;
                this.isProductDataImportScheduled = false;
                this.isProductPlatformDataValidationScheduled = false;
                this.isProductPlatformDataValidationSucceeded = this.jsonData.status === 'done';
                if (this.jsonData.status === 'aborted') {
                    this.isProductPlatformDataValidation = false;
                    this.isFailed.emit(true);
                    if (this.subscription) {
                        this.subscription.unsubscribe();
                    }
                } else if (this.jsonData.status === 'failed') {
                    this.isProductPlatformDataValidation = false;
                    this.errorList = this.jsonData.error;
                    this.isFailed.emit(true);
                    if (this.subscription) {
                        this.subscription.unsubscribe();
                    }
                } else if (this.jsonData.status === 'done') {
                    this.isProductPlatformDataImport = true;
                    this.isProductPlatformDataValidation = false;
                    this.isProductPlatformDataImportScheduled = false;
                }
            } else if (this.jsonData.stage === 'productPlatformExecution') {
                this.isProductDataImport = false;
                this.isProductDataValidationSucceeded = true;
                this.isProductPlatformDataValidation = false;
                this.isProductDataImportSucceeded = true;
                this.isProductPlatformDataValidationSucceeded = true;
                this.isProductDataValidation = false;
                this.isProductDataImportScheduled = false;
                this.isProductPlatformDataValidationScheduled = false;
                this.isProductPlatformDataImportScheduled = false;
                this.isProductPlatformDataImportSucceeded = this.jsonData.status === 'done'
                this.isProductPlatformDataImport = true;
                if (this.jsonData.status === 'aborted') {
                    this.isProductPlatformDataImport = false;
                    if (this.subscription) {
                        this.subscription.unsubscribe();
                    }
                } else if (this.jsonData.status === 'failed') {
                    this.isProductPlatformDataValidation = false;
                    this.errorList = this.jsonData.error;
                    this.isFailed.emit(true);
                    if (this.subscription) {
                        this.subscription.unsubscribe();
                    }
                } else if (this.jsonData.status === 'done') {
                    this.isProductPlatformDataImport = false;
                    this.isCompleted.emit(true);
                    if (!isInit && this.subscription) {
                        this.subscription.unsubscribe();
                    }
                }
            }
            if (isInit && (this.jsonData.stage !== 'productPlatformExecution' && this.jsonData.status !== 'done') && this.jsonData.status !== 'failed') {
                this.getData();
            }
        })
    }
}