import {ChangeDetectorRef, Component, OnInit, ViewChild, AfterViewChecked} from '@angular/core';
import {AwsAuthenticatedSdkService} from '@synthroneApp/services/aws-authenticated-sdk/aws-authenticated-sdk.service';
import {MessagesService} from '@angular/synthrone-design/services/messages/messages.service';
import {Observable, of} from 'rxjs';
import {CookieService} from '@angular/synthrone-design/services/cookie/cookie.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl} from '@angular/forms';
import {VirtualStorageService} from '@angular/synthrone-design/services/virtual-storage/virtual-storage.service';
import {Router, ActivatedRoute} from '@angular/router';
import {ApiService} from '@angular/synthrone-design/services/api/api.service';
import {InvokeCommand, LambdaClient} from '@aws-sdk/client-lambda';
import { Buffer } from 'buffer/';

@Component({
    selector: 'app-jobs-list',
    templateUrl: './jobs-list.component.html',
    styleUrls: ['./jobs-list.component.scss']
})
export class JobsListComponent implements OnInit, AfterViewChecked {
    @ViewChild('jobListTable') jobListTable;

    jobListResponse: any;
    jobList: any;
    tableFullData = [];
    isLoading = true;
    isTableLoading = true;

    filters = {
        platform: {
            filter: 'filters[platform]',
            data: [],
            value: ''
        },
        status: {
            filter: 'filters[status]',
            data: [
                {
                    label: 'Any',
                    value: ''
                },
                {
                    label: 'In Progress',
                    value: 'in-progress'
                },
                {
                    label: 'Action Required',
                    value: 'action-required'
                },
                {
                    label: 'Aborted',
                    value: 'aborted'
                },
                {
                    label: 'Failed',
                    value: 'failed'
                },
                {
                    label: 'Completed',
                    value: 'done'
                }
            ],
            value: ''
        }
    };

    tableColumnsConfig = {
        'platformName': {
            label: 'Platform',
            sortable: false,
            style: {
                flexGrow: 0.2
            }
        },
        'createdDate': {
            label: 'Date',
            sortable: false,
            type: 'time',
            style: {
                flexGrow: 0.2
            }
        },
        'fileName': {
            label: 'File Name',
            sortable: false,
            style: {
                maxWidth: '200px',
                whiteSpace: 'nowrap'
            }
        },
        'products': {
            label: 'Products',
            sortable: false,
            style: {
                flexGrow: 0.1
            }
        },
        'jobStage': {
            label: 'Stage',
            sortable: false,
            style: {
                flexGrow: 0.3
            }
        },
        'progress': {
            label: 'Progress',
            sortable: false,
            style: {
                width: '200px',
            }
        },
        'jobStatus': {
            label: 'Status',
            sortable: false,
            style: {
                flexGrow: 0.2
            }
        }
    };

    statusMap: any = {
        'new': 'New',
        'action-required': 'Action required',
        'in-progress': 'In progress',
        'failed': 'Failed',
        'aborted': 'Aborted',
        'done': 'Completed',
    };

    platformsFiltered: any[];
    platforms = [];
    public platformFilterCtrl: FormControl = new FormControl();
    public duringBriefDownload: string[] = [];

    constructor(
        protected awsService: AwsAuthenticatedSdkService,
        private messageService: MessagesService,
        private cookieService: CookieService,
        private sanitizer: DomSanitizer,
        private virtualService: VirtualStorageService,
        private router: Router,
        private route: ActivatedRoute,
        private cdRef: ChangeDetectorRef,
        protected api: ApiService,
    ) {
    }

    ngOnInit(): void {
        this.awsService.getInstance().subscribe(config => {
            this.route.queryParams.subscribe(params => {
                if (params.hasOwnProperty('platform')) {
                    this.filters['platform']['value'] = params['platform'];
                }
                if (params.hasOwnProperty('status')) {
                    this.filters['status']['value'] = params['status'];
                }
            });

            this.getJobList().then(response => {
                if (response.errorType) {
                    this.jobListResponse = [];
                    this.messageService.showSnack(response.errorMessage, 'error')
                } else {
                    this.jobListResponse = response;
                }
            }).then(() => {
                this.reloadFilters(() => {
                    this.isTableLoading = false;
                    this.isLoading = false;
                });
            });
        });
    }

    reloadFilters(callback = null) {
        const platformsData = this.virtualService.getItem('cst-filter-platform');
        this.platforms = platformsData;
        if (platformsData && platformsData.length > 0) {
            const data = platformsData.sort((a, b) => a.name.localeCompare(b.name));


            this.filters.platform.data = data.filter(item => item.briefSettings && Object.keys(item.briefSettings).length > 0);
            this.filters.platform.data.unshift({
                slug: '',
                name: 'Any'
            });
            this.platformsFiltered = [...this.filters.platform.data];
            this.platformFilterCtrl.valueChanges.subscribe((platformFilter) => {
                this.platformsFiltered = this.filters.platform.data.filter(c => c.name.trim().indexOf(platformFilter.toLowerCase().trim()) > -1)
            });
        } else {
            this.api.makeRequest('api_v1_explorer_platform_list', {}, 'tracker3').subscribe(
                (platforms: any) => {
                    this.filters.platform.data = platforms
                    const data =  this.filters.platform.data.sort((a, b) => a.name.localeCompare(b.name));
                    this.filters.platform.data = data.filter(item => item.briefSettings && Object.keys(item.briefSettings).length > 0);
                    this.filters.platform.data.unshift({
                        slug: '',
                        name: 'Any'
                    });
                    this.platformsFiltered = [...this.filters.platform.data];
                    this.platformFilterCtrl.valueChanges.subscribe((platformFilter) => {
                        this.platformsFiltered = this.filters.platform.data.filter(c => c.name.trim().indexOf(platformFilter.toLowerCase().trim()) > -1)
                    });
                },
                (error: any) => {
                    this.messageService.showSnack(error, 'error')
                }
            );
        }

        if (callback) {
            callback();
        }
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    getJobList(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'jobListing'
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    })
                });
            }
        )
    }

    getData = (): Observable<any> => {
        this.isTableLoading = true;
        this.jobList = this.jobListResponse;
        if (this.jobList && this.jobList.length > 0) {
            if (this.filters.status.value !== '') {
                this.jobList = this.jobList.filter(item => item['status'] === this.filters.status.value)
            }
            if (this.filters.platform.value && this.filters.platform.value !== '') {
                this.jobList = this.jobList.filter(item => item['platform'] === this.filters.platform.value)
            }
            this.jobList.sort((a, b) => b.created.localeCompare(a.created))
            this.jobList.forEach(result => {
                result['progress'] = this.prepareProgressBar(result.stage, result.status);
                result['jobStage'] = this.prepareStageLabel(result['status'], result['stage'])
                result['jobStatus'] = this.statusMap[result['status']];
                result['createdDate'] = result['created'] ? (new Date(result['created']).getTime() / 1000) : null;
                result['fileName'] = result['filename'];
                result['products'] = result['rows'];
                result['platformName'] = this.platforms && this.platforms.length > 0 && this.platforms.find(x => x.slug === result['platform']).name ?
                    this.platforms.find(x => x.slug === result['platform']).name : result['platform'];
            })
            this.tableFullData = this.jobList;
            this.isTableLoading = false;
            return of({
                data: this.jobList,
                currentOffset: 0,
                total: this.jobList.length
            });
        } else {
            this.tableFullData = [];
            this.isTableLoading = false;
            return of({
                data: [],
                currentOffset: 0,
                total: 0
            });
        }
    }

    prepareStageLabel(status, stage) {
        if (stage.toString() === '1') {
            return 'Trusted content gathering'
        } else if (stage.toString() === '2') {
            if (status === 'action-required') {
                return 'Trusted content verification'
            }
            return 'Platform data gathering'
        } else if (stage.toString() === '3') {
            if (status === 'action-required') {
                return 'Platform data verification'
            }
            return 'Import to Tracker'
        }
    }

    prepareProgressBar = (stage, status) => {
        const progress = (stage / 3) * 100;
        const html = '<div class="progress-bar">' +
            '<div class="progress ' + status + '" style="width:' + progress + '%">' +
            '</div>' +
            '<div class="label">' +
            stage + '/3' +
            '</div>' +
            '</div>'
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    import() {
        this.router.navigate(['/import']);
        this.isLoading = true;
    }

    filtersHandle() {
        this.isTableLoading = true;

        const usedFiltersKeys = Object.keys(this.filters).filter(key => this.filters[key]['value'] !== '');

        const queryParams = [];
        for (let i = 0; i < usedFiltersKeys.length; i++) {
            queryParams[usedFiltersKeys[i]] = this.filters[usedFiltersKeys[i]]['value'];
        }

        this.router.navigate([], {
            queryParams: queryParams
        });

        setTimeout(() => {
            this.jobListTable.getData()
            this.isTableLoading = false;
        }, 200);
    }

    refreshTable() {
        this.isTableLoading = true;

        this.getJobList().then(response => {
            if (response.errorType) {
                this.messageService.showSnack(response.errorMessage, 'error')
                this.jobListResponse = [];
                this.isTableLoading = false;
            } else {
                this.jobListResponse = response;
                this.jobListTable.getData();
                this.isTableLoading = false;
            }
        });
    }

    importDetails(jobId) {
        this.router.navigate(['/job', jobId]);
    }

    downloadBrief(jobId: string) {
        this.duringBriefDownload.push(jobId);
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'downloadBrief',
                        'data': {
                            'jobId': jobId
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-xlsx-handler',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    })
                });
            }).then(response => {
            if (response['errorType']) {
                this.duringBriefDownload = this.duringBriefDownload.filter((id: string) => id !== jobId);
                this.messageService.showSnack(response['errorMessage'], 'error');
            } else {
                this.duringBriefDownload = this.duringBriefDownload.filter((id: string) => id !== jobId);
                const downloader = document.createElement('a');
                downloader.href = response['url'];
                downloader.click();
                downloader.remove();
            }
        })
    }
}