
import { throwError, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AuthenticateService, CookieService, EndpointService } from '@angular/synthrone-design/services';
import { APP_CONFIG } from '@synthroneApp/app.config';

import { environment } from '@synthroneEnvironments/environment';

import * as io from 'socket.io-client';

@Injectable()
export class SynthroneDataService {

  constructor(protected api: ApiService,
    protected cookieService: CookieService,
    @Inject(APP_CONFIG) private config,
      private router: Router,
     @Inject(AuthenticateService) private authenticateService,
     private endpointService: EndpointService
    ) {

  }


  sdResetUserPassword = (email: any): Observable<any> => {
    return this.authenticateService.resetPassword(email);
  }

  sdChangeUserPassword = (passwordFirst: any, passwordSecond: any, token: any): Observable<any> => {
    return this.authenticateService.changePassword(passwordFirst, passwordSecond, token);
  }

  sdClientAssets = () => {
    return this.api.getData(this.config.api['manager'].endpoints['api_client_assets_opened_route'].path, {}, this.endpointService.getHost('manager')).pipe(catchError((err: any) => {
      this.router.navigate(['/404']);
      return throwError(() => { return err; });
    }));
  }
}
