import {Component, OnInit, ViewChild, Inject} from '@angular/core';
import {MessagesService} from '@angular/synthrone-design/services/messages/messages.service';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'errors-list-modal',
    templateUrl: './errors-list-modal.component.html',
    styleUrls: ['./errors-list-modal.component.scss']
})
export class ErrorsListModalComponent implements OnInit {
    @ViewChild('errorContainer') errorContainer;
    isLoading = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ErrorsListModalComponent>,
        private messageService: MessagesService
    ) {
    }

    ngOnInit(): void {
        this.isLoading = false;
    }

    copyText() {
        const textBox = document.createElement('textarea');
        textBox.value = this.errorContainer.nativeElement.innerText;
        document.body.appendChild(textBox);
        textBox.focus();
        textBox.select();
        document.execCommand('copy');
        document.body.removeChild(textBox);
        this.messageService.showSnack('Copied to clipboard', 'info');
    }

}