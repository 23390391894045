import {IAppSetupList, IAppSetupInfo, IClientAssets} from './interfaces/configs.interface';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthenticateService, CookieService, CommonFunctionsService } from '@angular/synthrone-design/services';

import {
    Router,
    Event as RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel
} from '@angular/router'
import {SYNTHRONE_CONFIG} from '@synthroneApp/app.config';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    loading: boolean = true;
    somethingToSetup: boolean = true;

    appSettingInfo: IAppSetupInfo = {
        message: "",
        progress: 0
    };

    customAppSetup: IAppSetupList = [
        {
            name: 'filter-platform',
            path: 'api_v1_explorer_platform_list',
            app: 'tracker3',
            message: 'Setting up platforms (1/3)',
            virtual: true
        },
        {
            name: 'filter-subCategory',
            path: 'api_v1_subcategory_list',
            app: 'tracker3',
            message: 'Setting up subcategories (2/3)',
            virtual: true
        },
        {
            name: 'filter-brand',
            path: 'api_v1_brand_list',
            app: 'tracker3',
            message: 'Setting up brands (3/3)',
            virtual: true
        }
    ];

    constructor(private router: Router, private authenticateService: AuthenticateService, private cdr: ChangeDetectorRef, private cookieService: CookieService, private commonService: CommonFunctionsService) {
        router.events.subscribe((event: RouterEvent) => {
            this.navigationInterceptor(event);
        });
    }

    ngOnInit() {
        this.tryTheme();
    }

    tryTheme(){
        let clientAssets: IClientAssets = this.cookieService.getCookie('clientAssets');

        if (clientAssets && clientAssets.theme) {
            let themeCheck = clientAssets.theme;
            if (themeCheck && themeCheck != "") {
                this.commonService.applyTheme(themeCheck);
            }
        }
    }


    navigationInterceptor(event: RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.somethingToSetup = this.authenticateService.shouldBasicBeSetUp(SYNTHRONE_CONFIG.appUuid, this.customAppSetup);
            this.appSettingInfo.progress = 0;
            this.appSettingInfo.message = "";
            setTimeout(() => {

                let bs = this.authenticateService.getBasicSettings('tracker3', this.appSettingInfo, this.customAppSetup).subscribe((res) => {
                    this.somethingToSetup = false;
                    this.loading = false;
                    bs.unsubscribe();
                    setTimeout(() => {
                        this.tryTheme();
                    })
                });
            })
        }
        if (event instanceof NavigationEnd) {
            if (!this.somethingToSetup) {
                this.loading = false;
            }
        }
        if (event instanceof NavigationCancel) {
            if (!this.somethingToSetup) {
                this.loading = false;
            }
        }
    }

}
