
import { NgModule } from '@angular/core';

import { SynthroneModule } from '@angular/synthrone-design/synthrone.module';
import { SynthroneMaterialModule } from './material.module';
import { MsAuthorizationApiModule } from 'ms-authorization-angular-sdk';

import { AppComponent } from '@synthroneApp/app.component';
import { AppLayoutComponent } from '@synthroneApp/components/app-layout/app-layout.component';
import { routing } from '@synthroneApp/app.routing';
import { SYNTHRONE_CONFIG, APP_CONFIG } from '@synthroneApp/app.config';
import { ConfigService } from '@angular/synthrone-design/services';
import { AppConfigService } from './services/app-config/app-config.service';
import { JobsListComponent } from './components/views/jobs-list/jobs-list.component';
import { AwsAuthenticatedSdkService } from './services/aws-authenticated-sdk/aws-authenticated-sdk.service';
import { SynthroneDataService } from './services/synthrone-data/synthrone-data.service';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import { ImportViewComponent } from './components/views/import/import.component';
import { UploadFileComponent } from './components/views/import/upload-file/upload-file.component';
import {DndDirective} from '@synthroneApp/components/views/import/upload-file/dnd.directive';
import { ErrorsListModalComponent } from './components/views/import/errors-list-modal/errors-list-modal.component';
import { JobDetailsViewComponent } from './components/views/jobs/job-details/job-details.component';
import { ImportStatusComponent } from './components/views/import/import-status/import-status.component';
import { ImportTableComponent } from './components/views/import/import-table/import-table.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NotificationsViewComponent } from './components/notifications/notifications.component';

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,
    JobsListComponent,
    ImportViewComponent,
    UploadFileComponent,
    DndDirective,
    ErrorsListModalComponent,
    JobDetailsViewComponent,
    ImportStatusComponent,
    ImportTableComponent,
    NotificationsViewComponent
  ],
  exports: [
    AppLayoutComponent
  ],
  entryComponents: [
  ],
  imports: [
    routing,
    SynthroneModule,
    SynthroneMaterialModule,
    MsAuthorizationApiModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    AppConfigService,
    AwsAuthenticatedSdkService,
    SynthroneDataService,
    { provide: APP_CONFIG, useValue: SYNTHRONE_CONFIG },
    { provide: ConfigService, useExisting: AppConfigService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic'
      }
    }
        // { provide: MAT_DIALOG_DATA, useValue: {} },
        // { provide: MatDialogRef, useValue: {} }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
