import {InjectionToken} from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');

export const SYNTHRONE_CONFIG = {
    appName: 'EXPLORER',
    appId: 'synthrone.explorer',
    appUuid: 'explorer',
    version: 2.0,
    requestThisApplicationRoutes: false,
    api: {
        notifications: {
            endpoints: {
                get_notifications: {
                    path: '/notifications',
                    methods: ['GET']
                }
            }
        },
        manager: {
            endpoints: {
                api_get_all_routes: {
                    path: '/routes',
                    methods: ['GET']
                },
                api_auth_login: {
                    path: '/login',
                    methods: ['POST']
                }
            }
        },
        tracker3: {
            endpoints: {
                api_get_all_routes: {
                    path: '/routes',
                    methods: ['GET']
                }
            }
        }
    }
};