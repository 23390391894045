export const environment = {
  production: true,
  aws: {
    region: 'eu-west-1',
    bucket: 'pg-synthrone-operational-bucket-v1x'
  },
  api: {
      https: true,
      msAuthorization: {
          url: 'https://api.authorization.synthrone.io/v1',
      },
      msClient: {
          url: 'https://api.client.synthrone.io/v1'
      },
      msUser: {
          url: 'https://api.user.synthrone.io/v1'
      },
      msSystem: {
          url: 'https://api.system.synthrone.io/v1'
      },
      manager: {
          host: 'manager.synthrone.io'
      },
      notifications: {
          host: 'microservices.synthrone.io'
      }
  }
};
