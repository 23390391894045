import { Injectable } from '@angular/core';
import { ConfigService } from '@angular/synthrone-design/services';

import { SYNTHRONE_CONFIG } from '@synthroneApp/app.config';
import { environment } from '@synthroneEnvironments/environment';

@Injectable()
export class AppConfigService extends ConfigService {

    public getAppConfig(): any {
      return SYNTHRONE_CONFIG;
    }
    public getAppEnvironment(): any {
      return environment;
    }

}