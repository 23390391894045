import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent, MaintenanceComponent } from '@angular/synthrone-design/components';
import { AuthenticateGuard, AuthorizeGuard, ChangePasswordGuard } from '@angular/synthrone-design/guards';
import { JobsListComponent } from './components/views/jobs-list/jobs-list.component';
import { ImportViewComponent } from '@synthroneApp/components/views/import/import.component';
import { JobDetailsViewComponent } from './components/views/jobs/job-details/job-details.component';
import { NotificationsViewComponent } from './components/notifications/notifications.component';

const appRoutes: Routes = [
  {
    path: '',
    component: JobsListComponent,
    canActivate: [AuthenticateGuard]
  },
  {
    path: 'import',
    component: ImportViewComponent,
    canActivate: [AuthenticateGuard]
  },
  {
    path: 'job/:id',
    component: JobDetailsViewComponent,
    canActivate: [AuthenticateGuard]
  },
  {
    path: 'notifications',
    component: NotificationsViewComponent,
    canActivate: [AuthenticateGuard, AuthorizeGuard],
    data: {
      permissions: ['manager.notifications']
    }
  },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: '**', component: PageNotFoundComponent }
];


export const routing = RouterModule.forRoot(appRoutes);
