import {Injectable} from '@angular/core';
import {Observable, of, mergeMap} from 'rxjs';
import {ApiService} from '@angular/synthrone-design/services/api/api.service';
import {environment} from '@synthroneEnvironments/environment';
import { DefaultServiceSDK } from 'ms-system-angular-sdk';

@Injectable()
export class AwsAuthenticatedSdkService {
    private awsCredentials: AwsRoleTemporaryCredentialsInterface;

    constructor(
        protected api: ApiService,
        private defaultServiceSDK: DefaultServiceSDK
    ) {
    }

    public getBucket = (): string => {
        return environment.aws.bucket;
    }

    public getRegion = (): string => {
        return environment.aws.region;
    }

    public getInstance = (): Observable<AwsRoleTemporaryCredentialsInterface> => {
        if (!this.awsCredentials || new Date(this.awsCredentials.expires) <= new Date()) {
            return this.defaultServiceSDK.awsTemporaryCredentialsExplorerGet().pipe(mergeMap((result: any) => {
                this.awsCredentials = {
                    region: this.getRegion(),
                    credentials: {
                        accessKeyId: result.key,
                        secretAccessKey: result.secret,
                        sessionToken: result.token
                    },
                    expires: result.expires
                };
                return of(this.awsCredentials);
            }));
        }

        return of(this.awsCredentials);
    };
}

// export interface AwsRoleTemporaryCredentialsInterface {
//     key: string,
//     secret: string,
//     token: string,
//     expires: string
// }
export interface AwsRoleTemporaryCredentialsInterface {
    region: string,
    credentials: {
        accessKeyId: string,
        secretAccessKey: string,
        sessionToken: string
    },
    expires: string
}