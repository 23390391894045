import {AfterViewInit, Component, OnDestroy, OnInit, EventEmitter, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ApiService} from '@angular/synthrone-design/services/api/api.service';
import {MessagesService} from '@angular/synthrone-design/services/messages/messages.service';
import {FormControl} from '@angular/forms';
import {VirtualStorageService} from '@angular/synthrone-design/services/virtual-storage/virtual-storage.service';
import {AwsAuthenticatedSdkService} from '@synthroneApp/services/aws-authenticated-sdk/aws-authenticated-sdk.service';
import {v4 as uuidv4} from 'uuid';
import {CookieService} from '@angular/synthrone-design/services/cookie/cookie.service';
import {ErrorsListModalComponent} from '@synthroneApp/components/views/import/errors-list-modal/errors-list-modal.component';
import {Router} from '@angular/router';
import {PutObjectCommand, S3Client} from '@aws-sdk/client-s3';
import {InvokeCommand, LambdaClient} from '@aws-sdk/client-lambda';
import { Buffer } from 'buffer/';

@Component({
    selector: 'upload-file',
    templateUrl: './upload-file.component.html',
    styleUrls: ['./upload-file.component.scss'],
})

export class UploadFileComponent implements OnInit, OnDestroy {
    @Output() jobDetails = new EventEmitter<any>();
    @Output() platformName = new EventEmitter<any>();
    @Output() platformType = new EventEmitter<any>();
    public isLoading = true;
    fileInput: any = null;
    fileUpload: any = null;
    wasFileChanged = false;
    subscription: any;
    dragClass = '';
    platformSlug: any = null;
    platforms: any;
    platformsFiltered: any[];
    public platformFilterCtrl: FormControl = new FormControl();
    duringExport = false;
    uploadedFile: any;
    fileKey: string;
    filePath: string;
    isValidation = false;
    isValidationSucceeded = false;
    isValidationFailed = false;
    errorList: any;
    job: any;
    file = {
        maxSize: 512,
        rows: 500
    }
    importTriggered = false;
    refreshPortfolio = false;

    filters = {
        'platforms': []
    };

    constructor(
        protected api: ApiService,
        private messageService: MessagesService,
        protected dialog: MatDialog,
        private virtualService: VirtualStorageService,
        protected awsService: AwsAuthenticatedSdkService,
        private cookieService: CookieService,
        private router: Router,
    ) {
    }

    ngOnInit() {
        this.reloadFilters();
    };

    reloadFilters() {
        this.platforms = this.virtualService.getItem('cst-filter-platform')
        if (this.platforms) {
            this.platforms = this.platforms.filter(item => item.briefSettings && Object.keys(item.briefSettings).length > 0)
            this.platforms = this.platforms.sort((a, b) => a.name.localeCompare(b.name))
            this.platformsFiltered = this.platforms
            this.platformFilterCtrl.valueChanges
                .subscribe((platformFilter) => {
                    this.platformsFiltered = this.platforms.filter(c => c.name.trim().indexOf(platformFilter.toLowerCase().trim()) > -1)
                });
            this.isLoading = false;
        } else {
            this.api.makeRequest('api_v1_explorer_platform_list', {}, 'tracker3').subscribe(
                (platforms: any) => {
                    this.platforms = platforms
                    const data = this.platforms.sort((a, b) => a.name.localeCompare(b.name));
                    this.platforms = data.filter(item => item.briefSettings && Object.keys(item.briefSettings).length > 0);
                    this.platforms.unshift({
                        slug: '',
                        name: 'Any'
                    });
                    this.platformsFiltered = [...this.platforms];
                    this.platformFilterCtrl.valueChanges.subscribe((platformFilter) => {
                        this.platformsFiltered = this.platforms.filter(c => c.name.trim().indexOf(platformFilter.toLowerCase().trim()) > -1)
                    });
                    this.isLoading = false;
                },
                (error: any) => {
                    this.messageService.showSnack(error, 'error')
                    this.isLoading = false;
                }
            );
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onFileDropped($fileList) {
        this.isValidationSucceeded = false;
        this.isValidationFailed = false;
        if (this.platformSlug) {
            this.prepareFilesList($fileList);
        } else {
            this.messageService.showSnack('Platform is required', 'info')
        }
    }

    fileBrowseHandler($event) {
        this.prepareFilesList($event.target.files);
    }

    uploadToS3(file): Promise<any> {
        this.isValidation = true;

        return new Promise((resolve, reject) => {
            this.awsService.getInstance().subscribe(config => {
                const s3 = new S3Client(config);

                this.fileKey = uuidv4();
                this.filePath = 'explorer/temporary-uploads/' + this.fileKey + '.xlsx';

                const params = {
                    Bucket: this.awsService.getBucket(),
                    Key: this.filePath,
                    Body: file
                };

                s3.send(
                    new PutObjectCommand(params), (error, data) => {
                        if (error) {
                            return reject(error)
                        }
                        return resolve(data);
                    }
                )
            });
        });
    };

    uploadBrief(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'uploadBrief',
                        'data': {
                            'brief': this.fileKey + '.xlsx',
                            'platform': this.platformSlug,
                            'filename': this.fileUpload.name,
                            'refreshPortfolio': this.refreshPortfolio
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-xlsx-handler',
                            Payload: JSON.stringify(lambdaOptions)
                        }),
                        function (error, data) {
                            if (error) {
                                return reject(error)
                            }
                            const responsePayload = data.Payload
                            return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                        }
                    );
                });
            }
        )
    };

    deleteFile(index: number) {
        this.fileUpload = null;
        this.isValidationSucceeded = false;
        this.fileInput = null;
    }

    clearSelection(index: number) {
        this.platformSlug = '';
        this.deleteFile(index);
    }

    prepareFilesList(files: any) {
        this.wasFileChanged = true;
        if (files.length > 1) {
            this.messageService.showSnack('Invalid number of files!', 'error');
            return;
        }

        if (files[0]['size'] >= (this.file.maxSize * 1024)) {
            this.messageService.showSnack('File size limit exceeded', 'info');
            return;
        }

        if (this.fileUpload != null && this.fileUpload.name === files[0]['name'] && this.fileUpload.lastModified === files[0]['lastModified'] && this.fileUpload.size === files[0]['size']) {
            this.messageService.showSnack('The file has been already added', 'info');
            return;
        }
        this.isValidationSucceeded = true;
        this.fileUpload = files[0];
    }

    importFile() {
        this.uploadToS3(this.fileUpload).then(response => {
            if (response) {
                this.uploadBrief().then(r => {
                    if (r.errorType) {
                        this.messageService.showSnack(r.errorMessage, 'error')
                        this.isValidation = false;
                    } else {
                        if (r && r.job_id) {
                            this.jobDetails.emit(r);
                            this.importTriggered = true;
                            this.job = r;
                            this.isValidationSucceeded = true;
                            this.jobProcessingTrigger().then(result => {
                                if (result.errorType) {
                                    this.messageService.showSnack(result.errorMessage, 'error')
                                } else {
                                    const url = this.job['job_id'] ? `/job/${this.job['job_id']}` : `/job/${this.job['jobId']}`;
                                    this.router.navigate([url]);
                                }
                                this.isLoading = false;
                            })
                        } else {
                            this.errorList = r;
                            this.isValidationFailed = true;
                            this.isValidationSucceeded = false;
                        }
                        this.platformName.emit(this.platformSlug);
                        this.platformType.emit(this.platforms.find(x => x.slug === this.platformSlug).briefSettings.type);
                        this.isValidation = false;
                    }
                });
            }
        })
    }

    exportEmptyTemplate() {
        if (this.platformSlug) {
            const briefSettings = this.platforms.find(x => x.slug === this.platformSlug).briefSettings
            if (briefSettings && briefSettings.type === 'automated') {
                window.location.href = `https://${this.awsService.getBucket()}.s3.amazonaws.com/explorer/templates/automated_template.xlsx`
            } else if (briefSettings && briefSettings.type === 'simplified') {
                window.location.href = `https://${this.awsService.getBucket()}.s3.amazonaws.com/explorer/templates/simplified_template.xlsx`
            } else {
                window.location.href = `https://${this.awsService.getBucket()}.s3.amazonaws.com/explorer/templates/manual_template.xlsx`
            }
        } else {
            this.messageService.showSnack('Platform is required', 'info')
        }
    };

    showErrorLogs() {
        const dialogRef = this.dialog.open(ErrorsListModalComponent, {
            width: '55%',
            height: '70%',
            panelClass: 'dialog-errors-list',
            autoFocus: false,
            data: this.errorList
        }).afterClosed().subscribe(result => {
            this.deleteFile(this.fileUpload);
        });
    }

    canGoNextStep = () => {
        return this.isValidationSucceeded && !this.importTriggered;
    };

    jobProcessingTrigger(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                     },
                    'action': {
                        'endpoint': 'jobProductProcessingTrigger',
                        'data': {
                            'jobId': this.job['job_id'] ? this.job['job_id'] : this.job['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }
        )
    }
}