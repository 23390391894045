import {Component, OnInit, AfterViewInit, ViewChild, AfterViewChecked, ChangeDetectorRef} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {UploadFileComponent} from '@synthroneApp/components/views/import/upload-file/upload-file.component';
import {Router} from '@angular/router';
import {CookieService} from '@angular/synthrone-design/services/cookie/cookie.service';
import {MessagesService} from '@angular/synthrone-design/services/messages/messages.service';

@Component({
    selector: 'import',
    templateUrl: './import.component.html',
    styleUrls: ['./import.component.scss']
})
export class ImportViewComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('uploadStep', {read: UploadFileComponent}) uploadStep;
    isLoading = false;
    progressValue = 20;
    steps = [];
    activeComponent: any;
    header: string;
    showImportButton = true;
    jobDetails: any
    platformSlug: string;
    platformType: string;

    constructor(
        private cookieService: CookieService,
        private messageService: MessagesService,
        private router: Router,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.steps = [
            {
                label: 'Step 1',
                componentName: 'upload-step',
                component: this.uploadStep,
                header: 'Brief import'
            },
            {
                label: 'Step 2',
                componentName: 'import-table',
                header: 'Trusted content verification'
            },
            {
                label: 'Step 3',
                componentName: 'import-table',
                header: 'Platform data verification'
            }
        ];

        this.isLoading = false;
    }

    ngAfterViewInit() {
        this.prepareSteps();
        this.activeComponent = this.steps[this.stepper.selectedIndex].component;
        this.header = this.steps[this.stepper.selectedIndex].header;
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    prepareSteps = () => {
        this.steps[0].component = this.uploadStep;
    }

    backToImports() {
        this.router.navigate(['/']);
        this.isLoading = true;
    }

    canDoNextStep() {
        if (this.activeComponent) {
            return this.activeComponent.canGoNextStep();
        }
        return false;
    }

    nextStep() {
        this.isLoading = true;
        this.activeComponent.importFile()
        this.isLoading = false;
    }

    getJobDetails($event) {
        this.jobDetails = $event
    }

    getPlatformSlug($event) {
        this.platformSlug = $event
    }

    getPlatformType($event) {
        this.platformSlug = $event
    }

}