import {Component, OnInit, AfterViewInit, ViewChild, AfterViewChecked, ChangeDetectorRef, Input} from '@angular/core';
import {MatStepper} from '@angular/material/stepper';
import {Router, ActivatedRoute} from '@angular/router';
import {ImportStatusComponent} from '@synthroneApp/components/views/import/import-status/import-status.component';
import {AwsAuthenticatedSdkService} from '@synthroneApp/services/aws-authenticated-sdk/aws-authenticated-sdk.service';
import {CookieService} from '@angular/synthrone-design/services/cookie/cookie.service';
import {MessagesService} from '@angular/synthrone-design/services/messages/messages.service';
import {ImportTableComponent} from '@synthroneApp/components/views/import/import-table/import-table.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationComponent} from '@angular/synthrone-design/components';
import {InvokeCommand, LambdaClient} from '@aws-sdk/client-lambda';
import { Buffer } from 'buffer/';

@Component({
    selector: 'job-details',
    templateUrl: './job-details.component.html',
    styleUrls: ['./job-details.component.scss']
})
export class JobDetailsViewComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('importStatus', {read: ImportStatusComponent}) importStatus;
    @ViewChild('importTable', {read: ImportTableComponent}) importTable;

    isLoading = true;
    job: any;
    progressValue = 20;
    steps = [];
    activeComponent: any;
    header: string;
    showAbortButton = false;
    jobDetails: any
    isTableComponentActive = false;
    isStatusComponentActive = false;
    platformSlug: string;
    platformType: string;
    isRetry = false;
    isTablePresent = false;
    isTableEmpty = false;
    isDownloading = {
        brief: false,
        excel: false
    };
    filters = {};

    constructor(
        protected awsService: AwsAuthenticatedSdkService,
        private cookieService: CookieService,
        private messageService: MessagesService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private cdRef: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.steps = [
            {
                label: 'Step 1',
                componentName: '',
                component: this.importStatus,
                header: 'Trusted content gathering'
            },
            {
                label: 'Step 2',
                componentName: '',
                component: this.importTable,
                header: 'Trusted content verification'
            },
            {
                label: 'Step 3',
                componentName: '',
                component: this.importTable,
                header: 'Platform data verification'
            }
        ];

        const sub = this.route.params.subscribe(params => {
            this.getJob(params['id']).then((jobData: any) => {
                if (jobData.errorMessage) {
                    this.messageService.showSnack(jobData['errorMessage'], 'error')
                    this.backToImports();
                } else {
                    this.job = jobData;
                    if (jobData.status === 'aborted') {
                        this.messageService.showSnack('Job already aborted', 'info')
                        this.backToImports();
                    } else {
                        this.activeComponent = this.steps[parseInt(this.job.stage, 0) - 1].component;
                        this.header = this.steps[parseInt(this.job.stage, 0) - 1].header
                        this.steps[parseInt(this.job.stage, 0) - 1].componentName = this.job.status === 'action-required' ? 'import-table' : 'import-status'
                        this.showAbortButton = true;
                        this.countProgress();
                        if (this.job.status === 'in-progress' && this.job.stage.toString() === '2') {
                            this.steps[1].header = 'Platform data gathering'
                        }
                    }
                }
            }).then (() => {
                    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
                    setTimeout(() => (this.stepper.selectedIndex = Number(this.job.stage) - 1), 10);
            });
        });

        sub.unsubscribe();

        this.isLoading = false;
    }

    ngAfterViewInit() {
        this.prepareSteps();
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    getJob(id: string): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'jobDetails',
                        'data': {
                            'jobId': id
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload;
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }
        )
    }

    prepareSteps = () => {
        this.steps[0].component = this.importStatus;
        this.steps[1].component = this.importTable;
        this.steps[2].component = this.importTable;
    }

    backToImports() {
        this.isLoading = true;
        this.router.navigate(['/']);
    }

    makeAbort() {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to abort? <br>The cancellation is irreversible.`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.abort();
            }
        })
    }

    abort() {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'jobAbort',
                        'data': {
                            'jobId': this.job['job_id'] ? this.job['job_id'] : this.job['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    })
                });
            }).then(response => {
            if (response['errorType']) {
                this.messageService.showSnack(response['errorMessage'], 'error')
            } else {
                this.messageService.showSnack('Job aborted', 'info')
                this.backToImports();
            }
        })
    }

    downloadBrief() {
        this.isDownloading.brief = true;
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'downloadBrief',
                        'data': {
                            'jobId': this.job['job_id'] ? this.job['job_id'] : this.job['jobId']
                        }
                    }
                }

                const activeFilters = this.transformFilters();
                if (Object.keys(activeFilters).length > 0) {
                    lambdaOptions.action.data['filters'] = activeFilters;
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-xlsx-handler',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    })
                });
            }).then(response => {
            if (response['errorType']) {
                this.messageService.showSnack(response['errorMessage'], 'error');
            } else {
                const downloader = document.createElement('a');
                downloader.href = response['url'];
                downloader.click();
                downloader.remove();
            }
            this.isDownloading.brief = false;
        })
    }

    downloadExcel() {
        this.isDownloading.excel = true;
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'exportProductResultsList',
                        'data': {
                            'jobId': this.job['job_id'] ? this.job['job_id'] : this.job['jobId']
                        }
                    }
                }

                const activeFilters = this.transformFilters();
                if (Object.keys(activeFilters).length > 0) {
                    lambdaOptions.action.data['filters'] = activeFilters;
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-xlsx-handler',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    })
                });
            }).then(response => {
            if (response['errorType']) {
                this.messageService.showSnack(response['errorMessage'], 'error');
            } else {
                const downloader = document.createElement('a');
                downloader.href = response['url'];
                downloader.click();
                downloader.remove();
            }
            this.isDownloading.excel = false;
        })
    }

    goToTrustedContentVerification($event) {
        if ($event === true) {
            this.getJob(this.job['job_id'] ? this.job['job_id'] : this.job['jobId']).then(response => {
                if (response.errorType) {
                    this.messageService.showSnack(response.errorMessage, 'error')
                } else {
                   this.job = response;
                }
            }).then(() => {
                if (this.stepper.selectedIndex !== (this.steps.length - 1)) {
                    this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
                    this.stepper.selectedIndex = parseInt(this.job.stage, 0) - 1
                }
                this.activeComponent = this.job.status === 'in-progress' ? this.importStatus : this.importTable;
                this.header = this.steps[this.stepper.selectedIndex].header
                this.steps[parseInt(this.job.stage, 0) - 1].componentName = 'import-table'
                this.countProgress();
            })
        }
    }

    isProcessCompleted(event) {
        if (event === true) {
            this.showAbortButton = false;
        }
    }
    isProcessFailed(event) {
        if (event === true) {
            this.showAbortButton = false;
        }
    }
    countProgress = () => {
        this.progressValue = (100 / this.steps.length) * Number(this.job.stage);
    }

    jobJsonEditFinished($event) {
        if ($event === true) {
            this.isLoading = true;
            this.steps[parseInt(this.job.stage, 0) - 1].componentName = 'import-status'
            this.header = 'Platform data gathering'
            this.isLoading = false;
        }
    }

    platformDataEditFinished($event) {
        if ($event === true) {
            this.isLoading = true;
            this.steps[parseInt(this.job.stage, 0) - 1].componentName = 'import-status'
            this.header = 'Import to Tracker'
            this.isLoading = false;
        }
    }
    retryGetPlatformData($event) {
        if ($event === true) {
            this.isLoading = true;
            this.getJob(this.job['job_id'] ? this.job['job_id'] : this.job['jobId']).then(response => {
                this.job = response;
            }).then(() => {
                this.isRetry = true;
                this.steps[0].componentName = 'import-status'
                this.steps[1].componentName = 'import-status'
                this.steps[2].componentName = ''
                this.header = 'Platform data gathering'
                this.progressValue = (100 / this.steps.length) * Number(this.job.stage)
                this.isLoading = false;
            })
        }
    }

    setTableStatus($event) {
        this.isTablePresent = $event;
    }

    onFiltersChanged($event) {
        this.filters = $event;
    }

    isAnyfilterActive(obj) {
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                if (this.isAnyfilterActive(obj[key])) {
                    return true;
                }
            } else {
                if (obj[key] === true) {
                    return true;
                }
            }
        }
        return false;
    }

    activeFilters() {
        const activeFilters = {};
        for (const key in this.filters) {
            for (const nestedKey in this.filters[key]) {
                if (this.filters[key][nestedKey] === true) {
                    activeFilters[key] = this.filters[key];
                }
            }

        }
        return activeFilters;
    }

    transformFilters() {
        const transformed = {};

        Object.entries(this.filters).forEach(([category, filterGroup]) => {
            const inactiveFilters = Object.entries(filterGroup)
                .filter(([_, isActive]) => category === 'type' ? !isActive : isActive)
                .map(([filterName]) => filterName);

            if (inactiveFilters.length > 0 && inactiveFilters.length < Object.keys(filterGroup).length) {
                transformed[category] = inactiveFilters;
            }
        });

        return transformed;
    }
}