import {Component, OnInit, Input, HostListener, ViewChild, AfterViewChecked, Output, EventEmitter} from '@angular/core';
import {AwsAuthenticatedSdkService} from '@synthroneApp/services/aws-authenticated-sdk/aws-authenticated-sdk.service';
import {MessagesService} from '@angular/synthrone-design/services/messages/messages.service';
import {ApiService} from '@angular/synthrone-design/services/api/api.service';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {CookieService} from '@angular/synthrone-design/services/cookie/cookie.service';
import {Router} from '@angular/router';
import {ErrorComponent} from '@angular/synthrone-design/components/dialogs/error/error.component';
import {VirtualStorageService} from '@angular/synthrone-design/services/virtual-storage/virtual-storage.service';
import {PermissionsService} from '@angular/synthrone-design/services/permissions/permissions.service';
import {ConfirmationComponent} from '@angular/synthrone-design/components';
import {FormControl} from '@angular/forms';
import {MatTable} from '@angular/material/table';
import {GetObjectCommand, S3Client} from '@aws-sdk/client-s3/';
import {InvokeCommand, LambdaClient} from '@aws-sdk/client-lambda';
import { Buffer } from 'buffer/';
import {BehaviorSubject, distinctUntilChanged} from 'rxjs';

export interface MouseEventCustom {
    rowId: number;
    colId: number;
    cellsType: string;
}

export interface Element {
    type: string;
    platform: string;
    ean: string;
    fpc: string;
    category: string;
    subCategory: string;
    brand: string;
    name: string;
    powersku: string;
    rpc: string;
    url: string;
    row: number;
    rowId: string;
    previewUrl: string;
    duplicatedRpc?: boolean;
    duplicatedUrl?: boolean;
    invalidUrl?: boolean;
}

interface SubCategories {
  value: string;
  name: string;
}

interface SubCategoriesGroup {
  name: string;
  data: SubCategories[];
}

@Component({
    selector: 'import-table',
    templateUrl: './import-table.component.html',
    styleUrls: ['./import-table.component.scss']
})
export class ImportTableComponent implements OnInit, AfterViewChecked {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('table') set table(table: MatTable<any>) {
        this.isTable.emit(!(this.isLoading || this.isTableLoading || !this.brands || !this.brands.length || !this.subCategories || !this.subCategories.length));
    }
    @Input() platformSlug: string;
    @Input() platformType: string;
    @Input() jobDetails: any;
    @Input() isActive: boolean;
    @Output() isJobJsonEditFinished = new EventEmitter<boolean>();
    @Output() isPlatformDataEditFinished = new EventEmitter<boolean>();
    @Output() isRetry = new EventEmitter<boolean>();
    @Output() isTable = new EventEmitter<boolean>();
    @Output() isTableEmpty = new EventEmitter<boolean>();
    @Output() filtersChanged = new EventEmitter<any>();
    public isLoading: boolean = true;
    public refreshUrl: boolean = false;
    public isTableLoading: boolean = true;
    public trackerProducts: number = 0;
    public newProducts: number = 0;
    public productsToConfirm: number = 0;
    public notFoundProducts: number = 0;
    public subCategories: any = []
    public brands: any = []
    public brandsFiltered: any[];
    public brandsFilterCtrl: FormControl = new FormControl();
    public subCategoriesFilterCtrl: FormControl = new FormControl();
    private brandsSubject = new BehaviorSubject<any[]>([]);
    private subCategoriesSubject = new BehaviorSubject<any[]>([]);
    public displayedColumns: string[] = ['type', 'platform', 'ean', 'fpc', 'category', 'subCategory', 'brand', 'name', 'powersku', 'rpc', 'url', 'actions'];
    public dataSource: any;
    private tableData: any; // represents the table’s current state (sorted, paginated, filtered)
    private tableMouseDown: MouseEventCustom;
    private tableMouseUp: MouseEventCustom;
    private FIRST_EDITABLE_ROW = 0;
    private LAST_EDITABLE_ROW: number;
    private FIRST_EDITABLE_COL = 2;
    private LAST_EDITABLE_COL = 6;
    private newCellValue: string;
    public jsonResult: any;
    public  allResults: number;
    private updatedJSON = [];
    private selectedValues = {
        'brand': '',
        'subCategory': ''
    };
    public selectedCellsState: boolean[][] = [];
    public canRetry: boolean = false;
    public isMultiSelection: boolean = false;
    public searchControl: any = {};
    public selectedEanAndFpcValues: string[] = [];
    private copyAblePairs = {
        'step-2': [['ean', 'fpc'], ['subCategory', 'brand']],
        'step-3': [['ean', 'fpc'], ['rpc', 'url']]
    };
    public filters = {
        'type': {
            'tracker': false,
            'new': false,
            'action-required': false,
            'not-found': false
        },
        'errors': {
            'duplicates': false,
            'invalid-urls': false
        }
    }

    constructor(
        protected awsService: AwsAuthenticatedSdkService,
        private messageService: MessagesService,
        protected api: ApiService,
        private dialog: MatDialog,
        private cookieService: CookieService,
        private router: Router,
        private virtualService: VirtualStorageService,
        public permissionService: PermissionsService
    ) {
    }

    ngOnInit(): void {
        this.canRetry = this.checkRetryPermission();
        this.platformType = this.platformType ? this.platformType : this.jobDetails.platformType;
        this.refreshUrl = this.jobDetails.refreshUrl ? this.jobDetails.refreshUrl : this.refreshUrl;
        if (this.jobDetails && this.jobDetails.stage.toString() === '3') {
            this.FIRST_EDITABLE_COL = 2;
            this.LAST_EDITABLE_COL = 10;
        }

        this.subCategories = this.virtualService.getItem('cst-filter-subCategory');
        if (this.subCategories) {
            this.setSubCategories(this.subCategories);
        } else {
            this.api.makeRequest('api_v1_subcategory_list', {}, 'tracker3').subscribe(
                (subCategories: any) => {
                    this.setSubCategories(subCategories);
                },
                (error: any) => {
                    this.messageService.showSnack(error, 'error');
                }
            );
        }

        this.subCategoriesFilterCtrl.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe((filterValue) => {
            this.filterSubCategories(filterValue);
        });

        this.brands = this.virtualService.getItem('cst-filter-brand');
        if (this.brands) {
            this.setBrands(this.brands);
        } else {
            this.api.makeRequest('api_v1_brand_list', { 'filters[isCompetitor]': 0 }, 'tracker3').subscribe(
                (brands: any) => {
                    this.setBrands(brands);
                },
                (error: any) => {
                    this.messageService.showSnack(error, 'error');
                }
            );
        }

        this.brandsFilterCtrl.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe((filterValue) => {
            this.filterBrands(filterValue);
        });

        if (this.jobDetails) {
            this.platformSlug = this.jobDetails['platform'] ? this.jobDetails['platform'] : this.platformSlug;
            this.getS3Object().then(response => {
                if (response.errorType) {
                    this.messageService.showSnack(response.errorMessage, 'error')
                } else {
                    response.Body.transformToString().then((result) => {
                      this.jsonResult = JSON.parse(result);
                      this.allResults = this.jsonResult.length;
                      this.prepareData();
                      this.LAST_EDITABLE_ROW = this.dataSource['filteredData'].length - 1;
                      this.isLoading = false;

                      if (this.notFoundProducts === this.allResults && this.jobDetails.stage.toString() === '2') {
                          setTimeout(() => {
                              this.dialog.open(ErrorComponent, {
                                  data: {
                                      message: 'No results found on tracker and browser. Job will be aborted!'
                                  }
                              }).afterClosed().subscribe((response) => {
                                  this.abort();
                              });
                          }, 1000);
                      }
                    });
                }
            })
        }
    }

    ngAfterViewChecked() {
        if (this.dataSource && !this.dataSource.sort) {
            this.dataSource.sort = this.sort;
        }
    }

    private setBrands(brands: any[]) {
        this.brands = brands
            .filter(brand => !brand.isCompetitor && !brand.deletedAt)
            .sort((a, b) => a.name.localeCompare(b.name));

        this.brandsSubject.next(this.brands);
        this.brandsFiltered = [...this.brands];
    }

    private setSubCategories(subCategories: any[]): void {
        this.subCategories = subCategories
            .filter(item => !item.deletedAt)
            .sort((a, b) => a.name.localeCompare(b.name));

        const groupedSubcategories = this.groupFilterData(this.subCategories);
        this.subCategoriesSubject.next(groupedSubcategories);

        this.virtualService.setItem('cst-filter-subCategory', this.subCategories);
    }

    private filterBrands(search: string) {
        const allBrands = this.brandsSubject.getValue();
        this.brandsFiltered = allBrands.filter(c =>
            c.name.toLowerCase().includes(search.toLowerCase().trim())
        );
    }

    private filterSubCategories(search: string): void {
        if (!search) {
            const sortedSubcategories = this.groupFilterData(this.subCategories);
            this.subCategoriesSubject.next(sortedSubcategories);
            return;
        }

        const filteredData = this.subCategories.filter(item =>
            item.name.toLowerCase().includes(search.toLowerCase().trim())
        );
        const filteredGroups = this.groupFilterData(filteredData);
        this.subCategoriesSubject.next(filteredGroups);
    }

    checkRetryPermission() {
        const token = this.cookieService.getCookie('appToken');
        const encodedData = atob(token.split('.').shift());
        const decodedData = JSON.parse(encodedData);
        return (decodedData && decodedData.superadmin && decodedData.superadmin === true);
    };

    getS3Object(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const params = {
                    Bucket: this.awsService.getBucket(),
                    Key: this.jobDetails.user_id ? `explorer/jobs/${this.jobDetails.user_id}/${this.jobDetails.job_id}/data.json` : `explorer/jobs/${this.jobDetails.userId}/${this.jobDetails.jobId}/data.json`
                };

                this.awsService.getInstance().subscribe(config => {
                    const s3 = new S3Client(config);

                    s3.send(
                        new GetObjectCommand(params), function (error, data) {
                            if (error) {
                                return reject(error)
                            }
                            return resolve(data);
                    });
                });
            })
    };

    download(type = 'import') {
        let endpoint = 'jobXlsxImportUrl';

        if (type === 'notFound' && this.jobDetails.stage.toString() === '2') {
            endpoint = 'exportBrowserProductsNotFound';
        } else if (type === 'internalError' && this.jobDetails.stage.toString() === '3') {
            endpoint = 'exportPlatformProductsNotFound';
        } else if (type === 'notFound' && this.jobDetails.stage.toString() === '3') {
            endpoint = 'exportPlatformProductsInternalError';
        }

        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': endpoint,
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-xlsx-handler',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }).then(response => {
                if (response['errorType']) {
                    this.messageService.showSnack(response['errorMessage'], 'error')
                } else {
                    window.open(response['url'], '_blank');
                }
            })
    }

    remove(type = 'notFound') {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to remove this rows? <br>Removal is irreversible.`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                if (this.notFoundProducts === this.allResults && type === 'notFound' ||
                    this.productsToConfirm === this.allResults && type === 'notFound' ||
                    this.notFoundProducts === this.allResults && type === 'internal') {
                        setTimeout(() => {
                            this.dialog.open(ErrorComponent, {
                                data: {
                                    message: 'All rows have been removed! Job will be aborted!'
                                }
                            }).afterClosed().subscribe((response) => {
                                this.abort();
                                return;
                            });
                        }, 1000);
                }

                let deletedItems;

                if (this.jobDetails.stage.toString() === '3' && type === 'notFound') {
                    deletedItems = this.dataSource['filteredData'].filter(item => item['type'] === 'action-required');
                } else if (this.jobDetails.stage.toString() === '3' && type === 'internal') {
                    deletedItems = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found');
                } else if (this.jobDetails.stage.toString() === '2') {
                    deletedItems = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found');
                }

                const chunkSize = 300;
                for (let i = 0; i < deletedItems.length; i += chunkSize) {
                    const chunk = deletedItems.slice(i, i + chunkSize);

                    const data = []
                    for (let i = 0; i < chunk.length; i++) {
                        this.dataSource.data = this.dataSource.data.filter(item => item['row'] !== chunk[i]['row']);
                        data.push({
                            'id': chunk[i].rowId,
                            'event': 'delete'
                        });
                    }

                    this.jobJsonEdit(data).then(response => {
                        if (response && response.errorType) {
                            this.messageService.showSnack(response.errorMessage, 'error');
                        } else {
                            if (this.jobDetails.stage.toString() === '3' && type === 'notFound') {
                                this.productsToConfirm = this.dataSource['filteredData'].filter(item => item['type'] === 'action-require').length;
                            } else if (this.jobDetails.stage.toString() === '3' && type === 'internal') {
                                this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length;
                            } else {
                                this.notFoundProducts = this.dataSource['filteredData'].filter(item => item['type'] === 'not-found').length;
                            }
                            this.allResults = this.allResults - chunk.length;
                            this.findDuplicatesAndInvalidUrls();

                            if (this.allResults === 0) {
                                setTimeout(() => {
                                    this.dialog.open(ErrorComponent, {
                                        data: {
                                            message: 'All rows have been removed! Job will be aborted!'
                                        }
                                    }).afterClosed().subscribe((response) => {
                                        this.abort();
                                        return;
                                    });
                                }, 1000);
                            }
                        }
                    });
                }
            }
        });
    }

    retry(type) {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to retry gathering?`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.isLoading = true;
                this.jobProcessingTrigger(true, type).then(result => {
                    if (result && result.errorType) {
                        this.messageService.showSnack(result.errorMessage, 'error');
                    } else {
                        this.isRetry.emit(true)
                    }
                });
            }
        });
    }

    getPlatformData() {
        this.isLoading = true;
        this.jobProcessingTrigger().then(response => {
            if (response && response.errorType) {
                this.messageService.showSnack(response.errorMessage, 'error');
            } else {
                this.isJobJsonEditFinished.emit(true);
                this.setAllValuesFalse(this.filters);
                this.filtersChanged.emit(this.filters);
            }
        });
    }

    importToTracker() {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to import to tracker?`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.isLoading = true;
                this.initTrackerImport().then(response => {
                    if (response && response.errorType) {
                        this.messageService.showSnack(response.errorMessage, 'error');
                        this.isLoading = false;
                    } else {
                        this.isPlatformDataEditFinished.emit(true)
                    }
                });
            }
        });
    }

    jobJsonEdit(rows): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'jobJsonEdit',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId'],
                            'rows': rows
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }
        )
    }

    updateSelectedCellsValues(text: string, object = {}, cellType = null) {
        if (this.tableMouseDown && this.tableMouseUp) {
            const dataCopy: Element[] = this.tableData;

            const { startCol, endCol, startRow, endRow } = this.normalizeSelectionRange(
                this.tableMouseDown.colId,
                this.tableMouseUp.colId,
                this.tableMouseDown.rowId,
                this.tableMouseUp.rowId
            );

            if (this.jobDetails.stage.toString() === '3') {
                const i = dataCopy.findIndex(item => item['row'] === object['row'])
                dataCopy[i][cellType] = text;
            }

            if (this.selectedValues && this.jobDetails.stage.toString() === '2') {
                if (this.tableMouseDown?.cellsType === this.tableMouseUp?.cellsType) {
                    for (let i = startRow; i <= endRow; i++) {
                        dataCopy[i][this.tableMouseUp.cellsType] = dataCopy[i]['type'] !== 'not-found' ? this.selectedValues[this.tableMouseUp.cellsType] : '';
                        dataCopy[i]['category'] = this.subCategories.find(el => el.name === dataCopy[i]['subCategory'])?.category.name;
                        const index = this.jsonResult.findIndex(item => item['rowNumber'] === dataCopy[i]['row'])
                        const updatedItem = this.jsonResult[index]
                        if (this.tableMouseUp.cellsType === 'brand') {
                            this.updatedJSON.push({
                                'id': updatedItem['id'],
                                'event': 'update',
                                'brand': dataCopy[i][this.tableMouseUp.cellsType]
                            })
                        } else if (this.tableMouseUp.cellsType === 'subCategory') {
                            this.updatedJSON.push({
                                'id': updatedItem['id'],
                                'event': 'update',
                                'subCategory': dataCopy[i][this.tableMouseUp.cellsType],
                                'category': dataCopy[i]['category']
                            })
                        }
                        if ((dataCopy[i]['brand'] === '' || dataCopy[i]['subCategory'] === '') && dataCopy[i]['type'] !== 'not-found') {
                            dataCopy[i]['type'] = 'action-required'
                        } else {
                            if (dataCopy[i].brand !== '' && dataCopy[i].subCategory !== '' && dataCopy[i].type === 'action-required') {
                                dataCopy[i].type = updatedItem.meta.tracker.gathered === true && updatedItem.meta.tracker.found === true ? 'tracker' : 'new';
                            }
                        }
                    }
                } else {
                    for (let i = startRow; i <= endRow; i++) {
                        dataCopy[i][this.tableMouseUp.cellsType] = dataCopy[i]['type'] !== 'not-found' ? this.selectedValues[this.tableMouseUp.cellsType] ? this.selectedValues[this.tableMouseUp.cellsType] :
                            dataCopy[i][this.tableMouseUp.cellsType] : '';
                        dataCopy[i][this.tableMouseDown.cellsType] = dataCopy[i]['type'] !== 'not-found' ? this.selectedValues[this.tableMouseDown.cellsType] ? this.selectedValues[this.tableMouseDown.cellsType] :
                            dataCopy[i][this.tableMouseDown.cellsType] : '';
                        dataCopy[i]['category'] = this.subCategories.find(el => el.name === dataCopy[i]['subCategory'])?.category.name;
                        const index = this.jsonResult.findIndex(item => item['rowNumber'] === dataCopy[i]['row'])
                        const updatedItem = this.jsonResult[index]
                        this.updatedJSON.push({
                            'id': updatedItem['id'],
                            'event': 'update',
                            'brand': dataCopy[i]['brand'],
                            'subCategory': dataCopy[i]['subCategory'],
                            'category': dataCopy[i]['category']
                        })
                        if ((dataCopy[i]['brand'] === '' || dataCopy[i]['subCategory'] === '') && dataCopy[i]['type'] !== 'not-found') {
                            dataCopy[i]['type'] = 'action-required'
                        } else {
                            if (dataCopy[i].brand !== '' && dataCopy[i].subCategory !== '' && dataCopy[i].type === 'action-required') {
                                dataCopy[i].type = updatedItem.meta.tracker.gathered === true && updatedItem.meta.tracker.found === true ? 'tracker' : 'new';
                            }
                        }
                    }
                }
                if (this.updatedJSON && this.updatedJSON.length > 0) {
                    this.jobJsonEdit(this.updatedJSON).then(response => {
                        if (response && response.errorType) {
                            this.messageService.showSnack(response.errorMessage, 'error');
                        } else {

                        }
                    }).then(() => {
                        this.dataSource['filteredData'] = dataCopy;
                        this.countElementTypes(this.dataSource.data);
                    });
                    this.updatedJSON = [];
                }
            } else if (this.jobDetails.stage.toString() === '3' && object) {
                const dataCopyIndex = dataCopy.findIndex(item => item['row'] === object['row'])
                const index = this.jsonResult.findIndex(item => item['rowNumber'] === dataCopy[dataCopyIndex]['row'])
                const updatedItem = this.jsonResult[index]
                if (updatedItem[cellType] !== text) {
                    if (cellType === 'url' && !object['invalidUrl']) {
                        this.updatedJSON.push({
                            'id': updatedItem['id'],
                            'event': 'update',
                            'url': text
                        })
                    } else if (cellType === 'rpc' && !object['duplicatedRpc']) {
                        this.updatedJSON.push({
                            'id': updatedItem['id'],
                            'event': 'update',
                            'rpc': text
                        })
                    }
                    if (this.updatedJSON && this.updatedJSON.length > 0) {
                        this.jobJsonEdit(this.updatedJSON).then(response => {
                            if (response && response.errorType) {
                                this.messageService.showSnack(response.errorMessage, 'error');
                            } else {
                                if (dataCopy[dataCopyIndex].rpc !== '' && dataCopy[dataCopyIndex].url !== '' && dataCopy[dataCopyIndex].rpc !== '-' && dataCopy[dataCopyIndex].url !== '-' &&
                                    (dataCopy[dataCopyIndex].type === 'action-required' || dataCopy[dataCopyIndex].type === 'not-found')) {
                                    dataCopy[dataCopyIndex].type = updatedItem['platformData'] ? 'new' : 'tracker';
                                }
                            }
                        }).then(() => {
                            this.countElementTypes(this.dataSource.data);
                        });
                    }
                    this.updatedJSON = [];
                }
            }
            this.dataSource['filteredData'] = dataCopy;
            this.countElementTypes(this.dataSource.data);
        }
        this.dataSource.filter = 'string_to_force_filtering';
    }

    tableSort(event) {
        this.unsetSelection();
    }

    onMouseDown(rowId: number, colId: number, cellsType: string, event) {
        if (!this.copyAblePairs[`step-${this.jobDetails.stage}`].some(pair => pair.includes(cellsType))) {
            return this.unsetSelection();
        }

        if (event.shiftKey && this.tableMouseDown) {
            this.tableMouseUp = { rowId, colId, cellsType };
            this.updateSelectedCellsState(this.tableMouseDown.colId, colId, this.tableMouseDown.rowId, rowId);
        } else {
            this.tableMouseDown = { rowId, colId, cellsType };
        }

        this.selectedEanAndFpcValues = [];
    }

    onMouseUp(rowId: number, colId: number, cellsType: string) {
        this.tableMouseUp = { rowId: rowId, colId: colId, cellsType: cellsType };
        if (this.tableMouseDown) {
            this.newCellValue = '';
            if (cellsType === 'ean' || cellsType === 'fpc') {
                this.selectedEanAndFpcValues.push(this.dataSource['filteredData'][rowId][cellsType]);
            }
            this.updateSelectedCellsState(this.tableMouseDown.colId, this.tableMouseUp.colId, this.tableMouseDown.rowId, this.tableMouseUp.rowId);
        }
    }

    inputChange(event, element, type) {
        event.target.value = event.target.value.trim();

        const index = this.dataSource.data.findIndex(item => item.rowId === element.rowId);
        if (index !== -1) {
            this.dataSource.data[index][type] = event.target.value;
            this.dataSource.data = [...this.dataSource.data];  // force update of dataSource
        }

        if (type === 'rpc' || type === 'url') {
            this.findDuplicatesAndInvalidUrls(type === 'rpc', type === 'url');
        }
        this.updateSelectedCellsValues(event.target.value, element, type);
    }

    updateSelectedCellsState(mouseDownColId: number, mouseUpColId: number, mouseDownRowId: number, mouseUpRowId: number) {
        this.clearSelectionState();

        const { startCol, endCol, startRow, endRow } = this.normalizeSelectionRange(
            mouseDownColId,
            mouseUpColId,
            mouseDownRowId,
            mouseUpRowId
        );

        this.isMultiSelection = (startRow !== endRow || startCol !== endCol);
        for (let i = startRow; i <= endRow; i++) {
            for (let j = startCol; j <= endCol; j++) {
                this.selectedCellsState[i][j] = true;
            }
        }
    }

    @HostListener('document:keydown', ['$event'])
    onKeyUp(event: KeyboardEvent): void {
        if (this.tableMouseDown && this.tableMouseUp) {
            const specialKeys: string[] = ['Enter', 'PrintScreen', 'Escape', 'cControl', 'NumLock', 'PageUp', 'PageDown', 'End',
                'Home', 'Delete', 'Insert', 'ContextMenu', 'Control', 'ControlAltGraph', 'Alt', 'Meta', 'Shift', 'CapsLock',
                'TabTab', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp', 'Pause', 'ScrollLock', 'Dead', '',
                'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'];

            if (event.key === 'Backspace') {
                const end: number = this.newCellValue.length - 1;
                this.newCellValue = this.newCellValue.slice(0, end);
            }
        }

        if (event.key === 'c' && (event.metaKey || event.ctrlKey) === true) {
            this.selectedValues = {
                'brand': '',
                'subCategory': ''
            };
            this.selectedEanAndFpcValues = [];

            if (this.tableMouseDown) {
                this.selectedValues[this.tableMouseDown.cellsType] = this.tableData[this.tableMouseDown.rowId][this.tableMouseDown.cellsType];
            }
            if (this.tableMouseUp) {
                this.selectedValues[this.tableMouseUp.cellsType] = this.tableData[this.tableMouseUp.rowId][this.tableMouseUp.cellsType];
            }

            const copyAblePairs = this.copyAblePairs[`step-${this.jobDetails.stage}`];
            if (copyAblePairs.some(pair => pair.includes(this.tableMouseDown?.cellsType) && pair.includes(this.tableMouseUp?.cellsType))) {

                const { startCol, endCol, startRow, endRow } = this.normalizeSelectionRange(
                    this.tableMouseDown.colId,
                    this.tableMouseUp.colId,
                    this.tableMouseDown.rowId,
                    this.tableMouseUp.rowId
                );

                for (let i = startRow; i <= endRow; i++) {
                    const rowEanFpcValues = [];
                    for (let j = startCol; j <= endCol; j++) {
                        if (this.tableData[i][this.displayedColumns[j]] !== '-') {
                            rowEanFpcValues.push(this.tableData[i][this.displayedColumns[j]]);
                        }
                    }
                    this.selectedEanAndFpcValues.push(rowEanFpcValues.join('\t'));
                }
            }
        }
        if (event.key === 'v' && (event.metaKey || event.ctrlKey) === true) {
            if (this.jobDetails.stage.toString() === '2') {
                if (this.selectedValues['brand'] !== '' || this.selectedValues['subCategory'] !== '') {
                    this.updateSelectedCellsValues(null);
                } else {
                    navigator['clipboard'].readText().then(data => {
                        if (data) {
                            this.updateSelectedCellsValues(data);
                        }
                    });
                }
            }
        }
        if (event.key === 'c' && (event.metaKey || event.ctrlKey) === true && this.selectedEanAndFpcValues.length > 0) {
            const eanAndFpcText = this.selectedEanAndFpcValues.join('\n');
            navigator.clipboard.writeText(eanAndFpcText);
        }
    }

    indexOfInArray(item: string, array: string[]): number {
        let index = -1;
        for (let i = 0; i < array.length; i++) {
            if (array[i] === item) {
                index = i;
            }
        }
        return index;
    }

    prepareData() {
        const ELEMENT_DATA: Element[] = [];
        this.jsonResult.forEach(item => {
            let type = '';
            let previewUrl = '';
            if (this.jobDetails.stage.toString() === '2') {
                if (item.meta) {
                    if (item.meta.tracker.gathered === true && item.meta.tracker.found === true) {
                        type = 'tracker'
                    } else if (item.meta.tracker.gathered === true && item.meta.tracker.found === false && item.meta.browser.gathered === true && item.meta.browser.found === true) {
                        type = 'new'
                    } else {
                        type = 'not-found'
                    }

                    if ((item['brand'] === '' || item['subCategory'] === '') && type !== 'not-found') {
                        type = 'action-required'
                    }
                }
            } else {
                    if (item.meta && item.meta.platform && item.meta.platform.error && item.meta.platform.error.type !== 'ProductNotFoundException' && ((!item.rpc || item.rpc === '') || (!item.url || item.url === ''))) {
                        type = 'not-found'
                    } else if (item.meta && item.meta.platform && item.meta.platform.error && item.meta.platform.error.type === 'ProductNotFoundException' && ((!item.rpc || item.rpc === '') || (!item.url || item.url === ''))) {
                        type = 'action-required'
                    } else if (item.platformData) {
                        type = 'new'
                    } else if (item.trackerData) {
                        type = 'tracker'
                    }
            }

            if (item.browserData && item.browserData.localization && item.browserData.localization.previewUrl) {
                previewUrl = item.browserData.localization.previewUrl
            }

            ELEMENT_DATA.push({
                type: type,
                platform: this.platformSlug,
                ean: item['ean'] ? item['ean'] : '-',
                fpc: item['fpc'] ? item['fpc'] : '-',
                category: item['category'],
                subCategory: item['subCategory'],
                brand: item['brand'],
                name: item['productName'] ? item['productName'] : '-',
                powersku: item['powersku'] ? item['powersku'] : '-',
                rpc: item['rpc'] ? item['rpc'] : '-',
                url: item['url'] ? item['url'] : '-',
                row: item['rowNumber'],
                rowId: item['id'],
                previewUrl: previewUrl ? previewUrl : null
            })
            this.selectedCellsState.push([false, false])
        })

        this.countElementTypes(ELEMENT_DATA);

        this.dataSource = new MatTableDataSource(ELEMENT_DATA);
        this.dataSource.connect().subscribe(currentTableData => {
            this.tableData = currentTableData;
            this.isTableEmpty.emit(currentTableData.length === 0);
        });
        this.findDuplicatesAndInvalidUrls();

        this.dataSource.filterPredicate = (data: any, filter: string): boolean => {
            const filterObj = this.filters;

            const activeTypeFilters = Object.keys(filterObj.type).filter(key => filterObj.type[key]);
            const activeErrorFilters = Object.keys(filterObj.errors).filter(key => filterObj.errors[key]);

            if (activeTypeFilters.length === 0 && activeErrorFilters.length === 0) {
                return true;
            }

            const excludeByType = activeTypeFilters.length > 0 && activeTypeFilters.includes(data.type);
            const includeDuplicates = filterObj.errors.duplicates && (data.duplicatedRpc ||data.duplicatedUrl);
            const includeInvalidUrls = filterObj.errors["invalid-urls"] && data.invalidUrl;

            return !excludeByType &&
                   (!filterObj.errors.duplicates || includeDuplicates) &&
                   (!filterObj.errors["invalid-urls"] || includeInvalidUrls);
        };

        this.isTableLoading = false;
    }

    openPreview(element) {
        window.open('http:' + element.previewUrl, '_blank')
    }

    updateJson(element, type, event) {
        const index = this.jsonResult.findIndex(item => item['rowNumber'] === element['row'])
        const updatedItem = this.jsonResult[index]
        const data = [];
        if (type === 'brand') {
            data.push({
                'id': updatedItem['id'],
                'event': 'update',
                'brand': event.value
            })
        } else if (type === 'subCategory') {
            data.push({
                'id': updatedItem['id'],
                'event': 'update',
                'subCategory': event.value,
                'category': this.subCategories.find(el => el.name === event.value)?.category.name
            })
        }
        element[type] = event.value

        this.jobJsonEdit(data).then(response => {
            if (response && response.errorType) {
                this.messageService.showSnack(response.errorMessage, 'error');
            } else {
                if (element.subCategory !== '' && element.brand !== '' && element.type === 'action-required') {
                    element.type = updatedItem['meta']['tracker'].found ? 'tracker' : 'new';
                }
                this.countElementTypes(this.dataSource.data);
            }
        });
    }

    deleteRow(element) {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to remove this row? <br>Removal is irreversible.`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.dataSource.data = this.dataSource.data.filter(item => item['row'] !== element['row']);
                const deletedItem = this.jsonResult.filter(item => item['rowNumber'] === element['row']);
                const data = []
                data.push({
                    'id': deletedItem[0].id,
                    'event': 'delete'
                })

                this.jobJsonEdit(data).then(result => {
                    if (result && result.errorType) {
                        this.messageService.showSnack(result.errorMessage, 'error');
                    } else {
                        this.countElementTypes(this.dataSource.data);
                        this.jsonResult = this.jsonResult.filter(row => row['id'] !== deletedItem[0].id)
                        this.allResults--;
                        this.findDuplicatesAndInvalidUrls();
                        this.dataSource.filter = 'string_to_force_filtering';

                        if (this.allResults === 0) {
                            setTimeout(() => {
                                this.dialog.open(ErrorComponent, {
                                    data: {
                                        message: 'All rows have been removed! Job will be aborted!'
                                    }
                                }).afterClosed().subscribe((response) => {
                                    this.abort();
                                });
                            }, 1000);
                        }
                    }
                });
            }
        });
    }

    canGetPlatformData() {
        const emptyBrands = this.dataSource.data.filter(item => item['brand'] === '').length
        const emptySubCategories = this.dataSource.data.filter(item => item['subCategory'] === '').length
        return this.notFoundProducts === 0 && this.productsToConfirm === 0 && this.dataSource.data && this.dataSource.data.length > 0 && emptyBrands === 0 && emptySubCategories === 0;
    }

    canImport() {
        return this.notFoundProducts === 0 && this.productsToConfirm === 0 && this.dataSource.data && this.dataSource.data.length > 0 && this.validationErrorsPresent() === false;
    }

    jobProcessingTrigger(retry = false, type = 'notFound'): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': retry ? 'jobPlatformProcessingRetryTrigger' : 'jobPlatformProcessingTrigger',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId'],
                            'refreshUrl': this.refreshUrl
                        }
                    }
                }
                if (retry) {
                    lambdaOptions['action']['data']['retryByErrorType'] = String(type);
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                })
            }
        )
    }

    makeAbort() {
        this.dialog.open(ConfirmationComponent, {
            disableClose: true,
            data: {
              message: `Are you sure you want to abort? <br>It is irreversible.`
            }
        }).afterClosed().subscribe((response) => {
            if (response === 'proceed') {
                this.abort();
            }
        })
    }

    abort() {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'jobAbort',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-api',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }).then(response => {
            if (response['errorType']) {
                this.messageService.showSnack(response['errorMessage'], 'error')
            } else {
                this.messageService.showSnack('Job aborted', 'info')
                this.backToImports();
            }
        })
    }

    backToImports() {
        this.router.navigate(['/']);
        this.isLoading = true;
    }

    initTrackerImport(): Promise<any> {
        return new Promise(
            (resolve, reject) => {
                const lambdaOptions = {
                    'meta': {
                        'synthroneToken': this.cookieService.getCookie('appToken')
                    },
                    'action': {
                        'endpoint': 'initTrackerImport',
                        'data': {
                            'jobId': this.jobDetails['job_id'] ? this.jobDetails['job_id'] : this.jobDetails['jobId']
                        }
                    }
                }

                this.awsService.getInstance().subscribe(config => {
                    const lambda = new LambdaClient(config);

                    lambda.send(
                        new InvokeCommand({
                            FunctionName: 'lambda-explorer-xlsx-handler',
                            Payload: JSON.stringify(lambdaOptions)
                        }), function (error, data) {
                        if (error) {
                            return reject(error)
                        }

                        const responsePayload = data.Payload
                        return resolve(JSON.parse(Buffer.from(responsePayload).toString()))
                    });
                });
            }
        )
    }

    validationErrorsPresent() {
        return this.dataSource.data.filter((el: Element) => {
            return el.duplicatedRpc || el.duplicatedUrl || el.invalidUrl || el.url === '' || el.rpc === '' || el.rpc === '-';
        }).length > 0;
    }

  groupFilterData(dataToGroup) {
    const data = [];
    const categories = dataToGroup.map(subcategory => subcategory.category).filter((category, index, self) => self.findIndex(c => c.id === category.id) === index);
    categories.forEach(item => {
      if (dataToGroup.filter(c => c['category']['id'] == item.id).slice().length > 0) {
        data.push({
          name: categories.find(c => c['id'] == item.id).name,
          data: dataToGroup.filter(c => c['category']['id'] == item.id).slice().sort((a, b) => a.name.localeCompare(b.name))
        });
      }
    });
    data.sort((a, b) => a.name.localeCompare(b.name));
    return data;
  }

  filterGroups(data) {
    const search = this.searchControl['subCategory'].value.toLowerCase();
    const dataGroupsCopy = this.groupFilterData(data);

    this.subCategoriesSubject.next(
      dataGroupsCopy.filter(group => {
        group.data = group.data.filter(item => item.name.toLowerCase().indexOf(search) > -1);
        return group.data.length > 0;
      })
    );
  }

  changeCategory(element) {
    const jsonResultElementIndex = this.jsonResult.findIndex(el => el.ean === element.ean);
    const dataSourceElementIndex = this.dataSource['filteredData'].findIndex(el => el.ean === element.ean);
    this.jsonResult[jsonResultElementIndex]['category'] = this.subCategories.find(el => el.name === element.subCategory)?.category.name
    this.dataSource['filteredData'][dataSourceElementIndex]['category'] = this.subCategories.find(el => el.name === element.subCategory)?.category.name
  }

    normalizeSelectionRange(mouseDownColId: number, mouseUpColId: number, mouseDownRowId: number, mouseUpRowId: number) {
        return {
            startCol: Math.min(mouseDownColId, mouseUpColId),
            endCol: Math.max(mouseDownColId, mouseUpColId),
            startRow: Math.min(mouseDownRowId, mouseUpRowId),
            endRow: Math.max(mouseDownRowId, mouseUpRowId)
        };
    }

    unsetSelection() {
        this.tableMouseDown = null;
        this.tableMouseUp = null;
        this.selectedValues = {
            'brand': '',
            'subCategory': ''
        };
        this.selectedEanAndFpcValues = [];
        this.newCellValue = '';
        this.isMultiSelection = false;

        this.clearSelectionState();
    }

    filterStatus(status: string): void {
        const typeFiltersKeys = Object.keys(this.filters.type);
        const activeStatusFilters = typeFiltersKeys.filter(key => this.filters.type[key]);
        if ((activeStatusFilters.length === typeFiltersKeys.length - 1) && !activeStatusFilters.includes(status)) {
            this.setAllValuesFalse(this.filters.type);
            this.filters.type[status] = true;
        } else {
            this.filters.type[status] = !this.filters.type[status];
        }

        this.dataSource.filter = 'string_to_force_filtering';
        this.filtersChanged.emit(this.filters);
    }

    filterDuplicates(): void {
        this.filters.errors['invalid-urls'] = false;
        this.dataSource.filter = 'string_to_force_filtering';
        this.filtersChanged.emit(this.filters);
    }

    filterInvalidUrls(): void {
        this.filters.errors.duplicates = false;
        this.dataSource.filter = 'string_to_force_filtering';
        this.filtersChanged.emit(this.filters);
    }

    findDuplicatesAndInvalidUrls(checkRpcs = true, checkUrls = true): void {
        const seenRpcs = new Set<string>();
        const seenUrls = new Set<string>();
        const duplicateRpcs = new Set<string>();
        const duplicateUrls = new Set<string>();


        this.dataSource.data.forEach((item: Element) => {
            if (checkRpcs && item.rpc && item.rpc !== '-') {
                const normalizedRpc = item.rpc.trim();
                if (seenRpcs.has(normalizedRpc)) {
                    duplicateRpcs.add(normalizedRpc);
                    item.duplicatedRpc = true;
                } else {
                    item.duplicatedRpc = false;
                }
                seenRpcs.add(normalizedRpc);
            }

            if (checkUrls && item.url && item.url !== '-') {
                const normalizedUrl = item.url.trim();
                if (seenUrls.has(normalizedUrl)) {
                    duplicateUrls.add(normalizedUrl);
                    item.duplicatedUrl = true;
                } else {
                    item.duplicatedUrl = false;
                }
                seenUrls.add(normalizedUrl);

                try {
                    new URL(normalizedUrl);
                    item.invalidUrl = false;
                } catch {
                    item.invalidUrl = true;
                }
            }
        });

        if (duplicateRpcs.size > 0 || duplicateUrls.size > 0) {
            this.dataSource.data.forEach((item: Element) => {
                if (item.rpc && duplicateRpcs.has(item.rpc.trim())) {
                    item.duplicatedRpc = true;
                }
                if (item.url && duplicateUrls.has(item.url.trim())) {
                    item.duplicatedUrl = true;
                }
            });
        }
    }

    countElementTypes(elements: Element[]): void {
        const counts = {
            'tracker': 0,
            'new': 0,
            'action-required': 0,
            'not-found': 0
        };

        elements.forEach((element: Element) => {
            counts[element.type]++;
        });

        this.trackerProducts = counts['tracker'];
        this.newProducts = counts['new'];
        this.productsToConfirm = counts['action-required'];
        this.notFoundProducts = counts['not-found'];
    }

    setAllValuesFalse(obj: any): void {
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                this.setAllValuesFalse(obj[key]);
            } else {
                obj[key] = false;
            }
        }
    }

    clearSelectionState(): void {
        for (let i = this.FIRST_EDITABLE_ROW; i <= this.LAST_EDITABLE_ROW; i++) {
            for (let j = this.FIRST_EDITABLE_COL; j <= this.LAST_EDITABLE_COL; j++) {
                this.selectedCellsState[i][j] = false;
            }
        }
    }
}
